<template>
  <!-- Begin Widget.NextEvents -->
  <div class="liste veranstaltungen">
    <div
      v-for="e in events"
      :key="e.id"
      class="item"
      :data-id="e.id"
    >
      <div class="datum_wrapper">
        <div v-if="e.startDate" class="datum">
          {{ $format.localDate(e.startDate) }}
        </div>
        <div v-if="e.timeString" class="zeit">
          {{ e.timeString }}
        </div>
      </div>
      <div class="text_wrapper">
        <div
          v-if="e.name"
          role="listitem"
          tabindex="0"
          class="titel"
          @click="gotoDetail(e)"
          @keyup.enter.prevent="gotoDetail(e)"
          @keyup.space.prevent="gotoDetail(e)"
        >
          {{ e.name }}
        </div>
        <div v-if="e.city" class="ort">
          {{ e.city }}
        </div>
        <div class="anmeldung">
          <a
            v-if="e.isBookingActionAvailable && isRegistrationExternalLink(e)"
            :href="registrationLink(e)"
            target="_blank"
          >
            {{ $t('widget.events.register') }}
          </a>
          <a
            v-if="e.isBookingActionAvailable && !isRegistrationExternalLink(e)"
            href="javascript:void(0);"
            @click.stop="gotoRegister(e)"
          >
            {{ $t('widget.events.register') }}
          </a>
        </div>
      </div>
    </div>
    <div class="debug">
      data-widget-toccofilter: {{ $config.values['widget-toccofilter'] }}<br>
      data-widget-pagesize: {{ $config.values['widget-pagesize'] }}<br>
      data-language: {{ $config.values.language }}
    </div>
  </div>
  <!-- End Widget.NextEvents -->
</template>

<script lang="ts">
  import Vue from 'vue';
  import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
  import WidgetEventListModel from '@/src/services/v2/model/widget-event-list-model';
  import GetWidgetEventsRequestModel from '@/src/services/v2/model/get-widget-events-request-model';
  import ApiPagingRequestModel from '@/src/services/v2/model/api-paging-request-model';
  import widgetEventUtilities from '@/src/utilities/widgets/WidgetEventUtilities';

  export default Vue.extend({
    layout: 'layout-widget-no-design',
    data: () => ({
      events: [] as WidgetEventListModel[],
    }),
    computed: {
      toccoFilter(): string {
        return this.$config.values['widget-toccofilter'] ?? '1==1';
      },
      pageSize():number {
        if (this.$config.values['widget-pagesize'] !== undefined) { return this.$config.values['widget-pagesize']; }
        return 10;
      },
    },
    async mounted() {
      try {
        const req = new GetWidgetEventsRequestModel();
        req.toccoFilter = this.toccoFilter;
        const page = new ApiPagingRequestModel();
        page.pageSize = this.pageSize;
        page.currentPage = 1;
        req.paging = page;
        const response = await this.$service.v2.api.widgetEvents.getWidgetEvents(req);
        this.events = response.items.map((i) => i.data);
      } catch (e) {
        const errorResponse = e as ServiceResponse;
        if (errorResponse.status === 503) {
          // this.events = errorResponse.data;
        }
        this.$log.error(e);
      }
    },
    methods: {
      registrationLink(model: WidgetEventListModel): string {
        return widgetEventUtilities.getEventRegisterUrl(model);
      },
      isRegistrationExternalLink(model: WidgetEventListModel): boolean {
        return !!this.$routerUtils.isUrl(this.registrationLink(model));
      },
      gotoRegister(model: WidgetEventListModel) {
        this.$routerUtils.pushRouteOrUrl(widgetEventUtilities.getEventRegisterUrl(model));
      },
      gotoDetail(model: WidgetEventListModel) {
        if (model.useEventLinkAsDetailPage) {
          window.open(model.detailUrl);
          return;
        }
        this.$routerUtils.pushRouteOrUrl(widgetEventUtilities.getEventDetailUrl(model));
      },
    },
  });
</script>
