<template>
  <!-- Begin Widget.event-detail -->
  <div v-if="responseError" class="angebot-detail error">
    <p-request-alert v-model="responseError"></p-request-alert>
  </div>
  <div v-else-if="isLoading" class="angebot-detail loading">
    <p-progress-linear indeterminate></p-progress-linear>
  </div>
  <div v-else class="angebot-detail">
    <a class="backlink" href="javascript:history.back();">&lt; {{ $t('widget.events.backToAppointmentOverview') }}</a>
    <div class="row title-row">
      <div class="col-12 titlewrapper">
        <h1>{{ model.name }}</h1>
        <div class="subtitle">
          <strong>{{ model.subtitle }}</strong>
        </div>
      </div>
    </div>
    <div class="row content-row">
      <div class="col-md-5 col-12 contentWrapper">
        <div class="uebersichtWrapper">
          <div class="boxtitle col-12 uebersicht-title">
            {{ $t('widget.events.overviewShort') }}
          </div>
          <div class="row uebersicht">
            <template v-if="!_.isEmpty(model.dates)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel data">
                {{ $t('widget.events.data') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 data">
                <template v-for="item in datesArray">
                  {{ item }}<br :key="item">
                </template>
              </div>
            </template>
            <template v-if="!_.isEmpty(model.duration)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel duration">
                {{ $t('widget.events.duration') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 duration">
                {{ model.duration }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.times)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel times">
                {{ $t('widget.events.times') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 times">
                {{ model.times }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.place)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel place">
                {{ $t('widget.events.place') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 place">
                {{ model.place }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.costs)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel costs">
                {{ $t('widget.events.costs') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 costs">
                {{ model.costs }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.registrationDeadline)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel registrationDeadline">
                {{ $t('widget.events.registrationDeadline') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 registrationDeadline">
                {{ model.registrationDeadline }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.availabilityString)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel availability">
                {{ $t('widget.events.availability') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 availability">
                {{ model.availabilityString }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.requirements)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel requirements">
                {{ $t('widget.events.requirementsTitle') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 requirements">
                {{ model.requirements }}
              </div>
            </template>
          </div>
        </div>
        <div class="infosWrapper">
          <div class="boxtitle col-12 infos-title">
            {{ $t('widget.events.contactAndDownloads') }}
          </div>
          <div class="row infos">
            <template v-if="!_.isEmpty(model.abbreviation)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel abbreviation-title">
                {{ $t('widget.events.abbreviation') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 abbreviation">
                {{ model.abbreviation }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.contactPerson)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel contactPerson-title">
                {{ $t('widget.events.contactPerson2') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 contactPerson">
                {{ model.contactPerson }}
              </div>
            </template>
            <template v-if="!_.isEmpty(model.contactEmail)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel contactEmail-title">
                {{ $t('widget.events.contactEmail2') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 contactEmail">
                <a :href="`maito:${model.contactEmail}`">{{ model.contactEmail }}</a>
              </div>
            </template>
            <template v-if="!_.isEmpty(model.detailUrl)">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel link-title">
                {{ $t('widget.events.link') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 link">
                <a :href="model.detailUrl">{{ model.detailUrl }}</a>
              </div>
            </template>
            <template v-if="model.hasDocuments">
              <div class="col-12 col-lg-5 col-md-12 col-sm-5 titel documents-title">
                {{ $t('widget.events.documents') }}
              </div>
              <div class="col-12 col-lg-7 col-md-12 col-sm-7 documents">
                <a
                  v-if="!_.isEmpty(model.eventInformationDocumentUrl)"
                  class="filedownload informationDocuments"
                  target="_blank"
                  :href="model.eventInformationDocumentUrl"
                >{{ $t('widget.events.informationDocuments') }}</a><br v-if="!_.isEmpty(model.eventInformationDocumentUrl)">
                <a
                  v-if="!_.isEmpty(model.locationMapUrl)"
                  class="filedownload sitePlan"
                  target="_blank"
                  :href="model.locationMapUrl"
                >{{ $t('widget.events.sitePlan') }}</a><br v-if="!_.isEmpty(model.locationMapUrl)">
                <a
                  v-if="!_.isEmpty(model.eventDocumentsUrl)"
                  class="filedownload courseDocuments"
                  target="_blank"
                  :href="model.eventDocumentsUrl"
                >{{ $t('widget.events.courseDocuments') }}</a><br v-if="!_.isEmpty(model.eventDocumentsUrl)">
                <a
                  v-if="!_.isEmpty(model.registrationFormUrl)"
                  class="filedownload informationDocuments"
                  target="_blank"
                  :href="model.registrationFormUrl"
                >{{ $t('widget.events.informationDocuments') }}</a>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-12 detailsWrapper">
        <div class="row details">
          <div class="boxtitle col-12 details-title">
            {{ $t('widget.events.details') }}
          </div>
          <div class="row col-12 details-content">
            <div v-if="!_.isEmpty(model.description)" class="section descriptionWrapper">
              <div class="section-title description-title">
                {{ $t('widget.events.description') }}
              </div>
              <div class="section-text description">
                {{ model.description }}
              </div>
            </div>
            <div v-if="!_.isEmpty(model.eventGoals)" class="section eventGoalsWrapper">
              <div class="section-title eventGoals-title">
                {{ $t('widget.events.goals') }}
              </div>
              <div class="section-text  eventGoals">
                {{ model.eventGoals }}
              </div>
            </div>
            <div v-if="!_.isEmpty(model.targetAudience)" class="section targetGroupWrapper">
              <div class="section-title targetGroup-title">
                {{ $t('widget.events.targetGroup') }}
              </div>
              <div class="section-text targetGroup">
                <template v-for="item in targetAudienceArray">
                  {{ item }}<br :key="item">
                </template>
              </div>
            </div>
            <div v-if="!_.isEmpty(model.leader)" class="section leaderWrapper">
              <div class="section-title leader-title">
                {{ $t('widget.events.leader') }}
              </div>
              <div class="section-text leader">
                <template v-for="item in leaderArray">
                  {{ item }}<br :key="item">
                </template>
              </div>
            </div>
            <div v-if="!_.isEmpty(model.remarks)" class="section remarksWrapper">
              <div class="section-title remarks-title">
                {{ $t('widget.events.remarks') }}
              </div>
              <div class="section-text remarks">
                {{ model.remarks }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row action-wrapper">
      <div class="col-md-5 col-12 cancellation-wrapper">
        <template v-if="!_.isEmpty(model.cancellation)">
          <div class="boxtitle cancellation-title">
            {{ $t('widget.events.cancellationConditions') }}
          </div>
          <div class="cancellation">
            {{ model.cancellation }}
          </div>
        </template>
      </div>
      <div class="col-md-7 col-12 register-wrapper">
        <div class="boxtitle register-title">
&nbsp;
        </div>
        <div v-if="model.isOnlineRegistrationActive" class="buttonwrapper">
          <a
            v-if="isRegistrationExternalLink"
            class="button register-link"
            target="_blank"
            :href="registrationLink"
          >{{ $t('widget.events.register') }}</a>
          <a
            v-else
            class="button register"
            href="javascript:void(0)"
            @click="gotoRegister"
          >{{ $t('widget.events.register') }}</a>
        </div>
      </div>
    </div>
  </div>
  <!-- End Widget.event-detail -->
</template>

<script lang="ts">
  import WidgetEventResourceViewModel from '@glittr/frontend-core/src-modules/tocco/widgets/events/services/v1/viewModel/resource/WidgetEventResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    layout: 'layout-widget-no-design',
    data: () => ({
      eventId: undefined as number | undefined,
      model: new WidgetEventResourceViewModel(),
      isLoading: false,
      isAccordeonDetailVisible: [] as boolean[],
      responseError: undefined as any,
      isRegistrationExternalLink: false,
      registrationLink: undefined as string | undefined,
    }),
    computed: {
      targetAudienceArray():string[] | null | undefined {
        return this.convertToArray(this.model.targetAudience);
      },
      leaderArray():string[] | null | undefined {
        return this.convertToArray(this.model.leader);
      },
      datesArray():string[] | null | undefined {
        return this.convertToArray(this.model.dates);
      },
    },
    async mounted() {
      const pp = this.$routerUtils.getQueryParams();
      const eventid = this.$config.values['widget-eventid'] ?? this.$route.params.eventid ?? pp.eventid;
      if (this._.isSet(eventid)) {
        this.eventId = Number.parseInt(eventid, 10);
        if (!Number.isNaN(this.eventId)) {
          await this.populateData();
        } else {
          this.responseError = `${this.$t('widget.events.invalidEventNumber')}: ${pp.eventid}`;
        }
      } else {
        this.responseError = this.$t('widget.events.noEventNumberSpecified');
        this.$log.error('No eventId given');
      }
      this.registrationLink = this.$service.v1.api.core.widgetEvents.getEventRegisterUrl(this.model);
      this.isRegistrationExternalLink = !!this.$routerUtils.isUrl(this.registrationLink ?? '');
    },
    methods: {
      convertToArray(text:string | null | undefined):string[] | null | undefined {
        return (text ?? '').split('\n');
      },
      gotoRegister() {
        this.$routerUtils.pushRouteOrUrl(this.$service.v1.api.core.widgetEvents.getEventRegisterUrl(this.model));
      },
      async populateData() {
        this.isLoading = true;
        try {
          const response = await this.$service.v1.api.core.widgetEvents.getWidgetEventDetail(this.eventId!);
          this.model = response;
        } catch (e) {
          this.$log.error(e);
        }
        this.isLoading = false;
      },
    },
  });
</script>
