import Vue from 'vue';
import WidgetEventListModelBase from '../_generated/modelBases/widget-event-list-model-base';

export default class WidgetEventListModel extends WidgetEventListModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  public get priceString():string {
    if (this.price === undefined) { return Vue.$t('widget.events.noCosts'); }
    return Vue.$format.currency(this.price!)!;
  }

  public get timeString():string | undefined {
    if (this.occurences && this.occurences.length > 0) {
      return this.occurences[0].timeString;
    }
    return '';
  }

  public get availabilityString():string {
    return this.getAvailabilityString(this.registrationStatus);
  }

  public get isBookingActionAvailable():boolean {
    return this.registrationStatus !== 'bookedUp' && this.isOnlineRegistrationActive === true;
  }

  public get hasAppointments():boolean {
    if (Vue._.isSet(this.occurences) && this.occurences.length > 0) {
      return true;
    }
    return false;
  }

  getAvailabilityString(status: string | undefined | null): string {
    switch (status) {
      case 'bookable':
        return Vue.$t('widget.events.status.bookable');
      case 'waitinglist':
        return Vue.$t('widget.events.status.waitinglist');
      case 'bookedUp':
        return Vue.$t('widget.events.status.bookedUp');
      default:
        return Vue.$t('widget.events.status.default');
    }
  }
}
