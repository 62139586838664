import Vue from 'vue';
import WidgetEventListModel from '../../services/v2/model/widget-event-list-model';

class WidgetEventUtilities {
  getEventDetailUrl(model: WidgetEventListModel) {
    const configPath = Vue.$config.getValueTranslated('widget-tocco-redirect-event-detail', { eventId: model.id!.toString() }) as string;
    return configPath ?? `/_widget/events/detail?eventid=${model.id}`;
  }

  getEventRegisterUrl(model: WidgetEventListModel) {
    if (model.registrationFormUrl) {
      return model.registrationFormUrl;
    }
    const configPath = Vue.$config.getValueTranslated('widget-tocco-redirect-event-register', { eventId: model.id!.toString() }) as string;
    return configPath ?? `/_widget/events/register?eventid=${model.id}`;
  }

  getEventSearchUrl() {
    const configPath = Vue.$config.getValueTranslated('widget-tocco-redirect-event-search', {}) as string;
    return configPath ?? '/_widget/events';
  }
}

const widgetEventUtilities = new WidgetEventUtilities();
export default widgetEventUtilities;
