<template>
  <div class="ilShopFormAddress">
    <h3 v-if="showTitle" class="formAddressTitle">
      {{ $t('shop.address.addressInvoice') }}
    </h3>
    <p-form v-if="addressInvoice" class="addressForm" :model="addressInvoice" no-summary>
      <p-row class="gender">
        <p-radio-field
          v-model="addressInvoice.sex"
          :disabled="invoiceDisabled"
          class="radioChoice female"
          :label="$t('shop.sex.woman')"
          true-value="f"
        ></p-radio-field>
        <p-radio-field
          v-model="addressInvoice.sex"
          :disabled="invoiceDisabled"
          class="radioChoice male"
          :label="$t('shop.sex.man')"
          true-value="m"
        ></p-radio-field>
      </p-row>
      <p-text-field
        v-model="addressInvoice.company"
        :disabled="invoiceDisabled"
        class="company"
        :label="$t('shop.address.company')"
      ></p-text-field>
      <p-text-field
        v-model="addressInvoice.firstname"
        :disabled="invoiceDisabled"
        class="firstname"
        :label="$t('shop.address.firstname')"
      ></p-text-field>
      <p-text-field
        v-model="addressInvoice.lastname"
        :disabled="invoiceDisabled"
        class="lastname"
        :label="$t('shop.address.lastname')"
      ></p-text-field>
      <p-text-field
        v-model="addressInvoice.street"
        :disabled="invoiceDisabled"
        class="street"
        :label="$t('shop.address.street')"
      ></p-text-field>
      <p-text-field
        v-model="addressInvoice.poBox"
        :disabled="invoiceDisabled"
        class="poBox"
        :label="$t('shop.address.poBox')"
      ></p-text-field>
      <p-city-field
        :value="{ postalCode: addressInvoice.postCode, city: addressInvoice.city }"
        :disabled="invoiceDisabled"
        class="postCode"
        @input="(location) => {
          addressInvoice.city = location.city;
          addressInvoice.postCode = location.postalCode
        }"
      ></p-city-field>
      <p-phone-field
        v-model="addressInvoice.telephone"
        context-icon="phone"
        :disabled="invoiceDisabled"
        class="telephone"
        :label="$t('shop.address.telephone')"
      ></p-phone-field>
      <p-phone-field
        v-model="addressInvoice.fax"
        context-icon="phone"
        :disabled="invoiceDisabled"
        class="fax"
        :label="$t('shop.address.fax')"
      ></p-phone-field>
      <p-text-field
        v-model="addressInvoice.email"
        :disabled="shippingDisabled"
        class="email"
        context-icon="email"
        :label="$t('shop.address.emailOrderRecipient')"
      ></p-text-field>
    </p-form>
    <div v-if="canSetDeliveryAddress" class="sectionShippingAddress">
      <div class="sectionShippingAddressActions">
        <span class="isShippingSameLabel">{{ $t('shop.shoppingBasket.isTheShippingAddressTheSame') }}</span>
        <p-row class="isShippingSame">
          <p-radio-field
            v-model="isShippingSameAsInvoice"
            :label="$t('core.app.yes')"
            class="radioChoice yes"
            :true-value="true"
          ></p-radio-field>
          <p-radio-field
            v-model="isShippingSameAsInvoice"
            :label="$t('core.app.no')"
            class="radioChoice no"
            :true-value="false"
          ></p-radio-field>
        </p-row>
      </div>
      <div v-if="!isShippingSameAsInvoice && addressShipping">
        <h3>{{ $t('shop.address.addressShipping') }}</h3>
        <p-form :model="addressShipping" no-summary>
          <p-row class="gender">
            <p-radio-field
              v-model="addressShipping.sex"
              :disabled="shippingDisabled"
              class="radioChoice female"
              :label="$t('shop.sex.woman')"
              true-value="f"
            ></p-radio-field>
            <p-radio-field
              v-model="addressShipping.sex"
              :disabled="shippingDisabled"
              class="radioChoice male"
              :label="$t('shop.sex.man')"
              true-value="m"
            ></p-radio-field>
          </p-row>
          <p-text-field
            v-model="addressShipping.company"
            :disabled="shippingDisabled"
            class="company"
            :label="$t('shop.address.company')"
          ></p-text-field>
          <p-text-field
            v-model="addressShipping.firstname"
            :disabled="shippingDisabled"
            class="firstname"
            :label="$t('shop.address.firstname')"
          ></p-text-field>
          <p-text-field
            v-model="addressShipping.lastname"
            :disabled="shippingDisabled"
            class="lastname"
            :label="$t('shop.address.lastname')"
          ></p-text-field>
          <p-text-field
            v-model="addressShipping.street"
            :disabled="shippingDisabled"
            class="street"
            :label="$t('shop.address.street')"
          ></p-text-field>
          <p-text-field
            v-model="addressShipping.poBox"
            :disabled="shippingDisabled"
            class="poBox"
            :label="$t('shop.address.poBox')"
          ></p-text-field>
          <p-city-field
            :value="{ postalCode: addressShipping.postCode, city: addressShipping.city }"
            :disabled="shippingDisabled"
            @input="(location) => {
              addressShipping.city = location.city;
              addressShipping.postCode = location.postalCode
            }"
          ></p-city-field>
          <p-phone-field
            v-model="addressShipping.telephone"
            context-icon="phone"
            :disabled="shippingDisabled"
            class="telephone"
            :label="$t('shop.address.telephone')"
          ></p-phone-field>
          <p-phone-field
            v-model="addressShipping.fax"
            context-icon="phone"
            :disabled="shippingDisabled"
            class="fax"
            :label="$t('shop.address.fax')"
          ></p-phone-field>
          <p-text-field
            v-model="addressShipping.email"
            :disabled="shippingDisabled"
            class="email"
            context-icon="email"
            :label="$t('shop.address.email')"
          ></p-text-field>
        </p-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';
  import AddressModel from '@/src/services/v2/model/address-model';
  import shoppingBasket from '../../utilities/shoppingBasket';

  export default Vue.extend({
    name: 'IlShopFormAddress',
    props: {
      showTitle: { type: Boolean, default: true },
      canSetDeliveryAddress: { type: Boolean, default: true },
      invoiceDisabled: { type: Boolean, default: false },
      shippingDisabled: { type: Boolean, default: false },
    },
    data: () => ({
      isFormValid: true,
      isShippingSameAsInvoice: true as boolean | undefined,
      addressInvoice: new AddressModel(),
      addressShipping: new AddressModel(),
    }),
    computed: {

    },
    watch: {
      addressInvoice: {
        deep: true,
        handler(newValue) {
          shoppingBasket.setInvoiceAddress(newValue);
          this.validateForm();
        },
      },
      addressShipping: {
        deep: true,
        handler(newValue) {
          shoppingBasket.setShippingAddress(newValue);
          this.validateForm();
        },
      },
      isShippingSameAsInvoice: {
        handler(newValue) {
          shoppingBasket.setIsShippingSameAsInvoice(newValue);
          if (_.isEmpty(this.addressShipping)) {
            this.addressShipping = new AddressModel(this.addressInvoice as any);
          }
          this.validateForm();
        },
      },
    },
    beforeMount() {
      this.addressInvoice = shoppingBasket.getInvoiceAddress() ?? new AddressModel();
      this.addressShipping = shoppingBasket.getShippingAddress() ?? new AddressModel();
      this.isShippingSameAsInvoice = shoppingBasket.getIsShippingSameAsInvoice()!;
      if (_.isNil(this.isShippingSameAsInvoice)) {
        shoppingBasket.setIsShippingSameAsInvoice(true);
        this.isShippingSameAsInvoice = true;
      }
      this.$nextTick(() => {
        this.validateForm();
      });
    },
    methods: {
      validateForm() {
        const invoiceAddressValid = !!this.addressInvoice?.valid;
        const overrideShipping = this.isShippingSameAsInvoice || !this.canSetDeliveryAddress;
        const shippingAddressValid = overrideShipping || !!this.addressShipping?.valid;
        const isFormValid = invoiceAddressValid && shippingAddressValid;
        this.$emit('valid', isFormValid);
      },
    },
  });
</script>
