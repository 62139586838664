// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetArticlesListRequestModel from '../../model/get-articles-list-request-model';
import GetArticleByIdRequestModel from '../../model/get-article-by-id-request-model';
import GetByArticleNumberRequestModel from '../../model/get-by-article-number-request-model';
import GetArticlePreviewImageByIdRequestModel from '../../model/get-article-preview-image-by-id-request-model';
import GetArticleDetailImageByIdRequestModel from '../../model/get-article-detail-image-by-id-request-model';
import UpdateBasketRequestModel from '../../model/update-basket-request-model';
import GetCategoriesRequestModel from '../../model/get-categories-request-model';
import CreateOrderRequestModel from '../../model/create-order-request-model';
import IsUsernameAvailableForShopRequestModel from '../../model/is-username-available-for-shop-request-model';
import RegisterForShopRequestModel from '../../model/register-for-shop-request-model';
import LoginForShopRequestModel from '../../model/login-for-shop-request-model';
import ResetPasswordForShopRequestModel from '../../model/reset-password-for-shop-request-model';
import ArticlePagingResultModel from '../../model/article-paging-result-model';
import ArticleModel from '../../model/article-model';
import BasketModel from '../../model/basket-model';
import CategoryListModel from '../../model/category-list-model';
import UserAccountModel from '../../model/user-account-model';

export default (service: Servicelayer) => ({
  /**  */
  async getArticlesList(request: GetArticlesListRequestModel, config?: RequestConfig) {
    const requestDTO = GetArticlesListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-articles-list'] ?? 'Articles';
    const response = await service.get<any>(endpointPath, {
      query: {
        language: requestDTO.language,
        categoryId: requestDTO.categoryId,
        searchTerm: requestDTO.searchTerm,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ArticlePagingResultModel.toModel(dto));
  },
  /**  */
  async getArticleById(request: GetArticleByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetArticleByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-article-by-id'] ?? 'Articles/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ArticleModel.toModel(dto));
  },
  /**  */
  async getByArticleNumber(request: GetByArticleNumberRequestModel, config?: RequestConfig) {
    const requestDTO = GetByArticleNumberRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-by-article-number'] ?? 'Articles/ByArticleNumber/{articleNumber}';
    endpointPath = endpointPath.replace(/\{articleNumber\}/g, `${requestDTO.articleNumber}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(ArticleModel.toModel(dto));
  },
  /**  */
  async getArticlePreviewImageById(request: GetArticlePreviewImageByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetArticlePreviewImageByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-article-preview-image-by-id'] ?? 'Articles/{id}/PreviewImage';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async getArticleDetailImageById(request: GetArticleDetailImageByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetArticleDetailImageByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-article-detail-image-by-id'] ?? 'Articles/{id}/DetailImage';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async updateBasket(request: UpdateBasketRequestModel, config?: RequestConfig) {
    const requestDTO = UpdateBasketRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-basket'] ?? 'Basket';
    const response = await service.post<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        basketItems: requestDTO.basketItems,
        addresses: requestDTO.addresses,
        isShippingSameAsInvoice: requestDTO.isShippingSameAsInvoice,
        summary: requestDTO.summary,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(BasketModel.toModel(dto));
  },
  /**  */
  async getCategories(request: GetCategoriesRequestModel, config?: RequestConfig) {
    const requestDTO = GetCategoriesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-categories'] ?? 'Categories';
    const response = await service.get<any>(endpointPath, {
      query: {
        language: requestDTO.language,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(CategoryListModel.toModel(dto));
  },
  /**  */
  async createOrder(request: CreateOrderRequestModel, config?: RequestConfig) {
    const requestDTO = CreateOrderRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-order'] ?? 'Orders';
    const response = await service.post<any>(endpointPath, {
      body: {
        userId: requestDTO.userId,
        orderNumber: requestDTO.orderNumber,
        wantsToBecomeAMember: requestDTO.wantsToBecomeAMember,
        priceCategory: requestDTO.priceCategory,
        clientRemark: requestDTO.clientRemark,
        basket: requestDTO.basket,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async isUsernameAvailableForShop(request: IsUsernameAvailableForShopRequestModel, config?: RequestConfig) {
    const requestDTO = IsUsernameAvailableForShopRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-is-username-available-for-shop'] ?? 'UserAccounts/IsUsernameAvailable';
    const response = await service.get<any>(endpointPath, {
      query: {
        username: requestDTO.username,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as boolean | undefined);
  },
  /**  */
  async registerForShop(request: RegisterForShopRequestModel, config?: RequestConfig) {
    const requestDTO = RegisterForShopRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-register-for-shop'] ?? 'UserAccounts/Register';
    const response = await service.post<any>(endpointPath, {
      body: {
        addressType: requestDTO.addressType,
        city: requestDTO.city,
        company: requestDTO.company,
        email: requestDTO.email,
        fax: requestDTO.fax,
        firstname: requestDTO.firstname,
        lastname: requestDTO.lastname,
        sex: requestDTO.sex,
        poBox: requestDTO.poBox,
        postCode: requestDTO.postCode,
        street: requestDTO.street,
        telephone: requestDTO.telephone,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(UserAccountModel.toModel(dto));
  },
  /**  */
  async loginForShop(request: LoginForShopRequestModel, config?: RequestConfig) {
    const requestDTO = LoginForShopRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-login-for-shop'] ?? 'UserAccounts/Login';
    const response = await service.post<any>(endpointPath, {
      body: {
        username: requestDTO.username,
        password: requestDTO.password,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(UserAccountModel.toModel(dto));
  },
  /**  */
  async resetPasswordForShop(request: ResetPasswordForShopRequestModel, config?: RequestConfig) {
    const requestDTO = ResetPasswordForShopRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-reset-password-for-shop'] ?? 'UserAccounts/ResetPassword';
    const response = await service.post<any>(endpointPath, {
      body: {
        username: requestDTO.username,
      },
      ...config,
    });
  },
});
