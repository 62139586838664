import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ArticleModel from '../services/v2/model/article-model';
import BasketItemModel from '../services/v2/model/basket-item-model';
import BasketModel from '../services/v2/model/basket-model';
import UpdateBasketRequestModel from '../services/v2/model/update-basket-request-model';
import UserAccountModel from '../services/v2/model/user-account-model';
import AddressModel from '../services/v2/model/address-model';
import CreateOrderRequestModel from '../services/v2/model/create-order-request-model';
import AddressDTO from '../services/v2/_generated/dto/address-dto';
import UserAccountDTO from '../services/v2/_generated/dto/user-account-dto';

class ShoppingBasketUtils {
  protected basketStorageKey = 'shoppingBasket';

  setLastOrderNumber(orderNumber: string | number) {
    Vue.$sessionStorage.set('lastOrderNumber', orderNumber);
  }

  getLastOrderNumber() {
    return Vue.$sessionStorage.get('lastOrderNumber');
  }

  getArticleInfo(articleId: string | number) {
    const shoppingBasket = this.getLocalBasket();
    const index = shoppingBasket.basketItems!.findIndex((basketItem: any) => basketItem.article?.id === articleId);
    return {
      index,
      shoppingBasket,
      article: shoppingBasket.basketItems![index],
    };
  }

  getCount(article: ArticleModel) {
    const articleInfo = this.getArticleInfo(article.id!);
    return articleInfo.article?.count ?? 0;
  }

  setCount(article: ArticleModel, count: number) {
    const { index, shoppingBasket } = this.getArticleInfo(article.id!);
    if (!shoppingBasket.basketItems) {
      shoppingBasket.basketItems = [];
    }
    if (index >= 0) {
      shoppingBasket.basketItems[index].count = count;
      if (shoppingBasket.basketItems[index].count! < 1) {
        // Zero articles, remove from basket
        shoppingBasket.basketItems.splice(index, 1);
      }
    } else if (count > 0) {
      const basketItem = new BasketItemModel({} as any);
      basketItem.count = count;
      basketItem.article = article;
      shoppingBasket.basketItems.push(basketItem);
    }
    this.setLocalBasket(shoppingBasket);
  }

  add(article: ArticleModel) {
    const count = this.getCount(article);
    this.setCount(article, count + 1);
  }

  remove(article: ArticleModel) {
    const count = this.getCount(article);
    this.setCount(article, count - 1);
  }

  clear() {
    Vue.$sessionStorage.set(this.basketStorageKey, { basketItems: [] });
  }

  delete(article: ArticleModel) {
    const { index, shoppingBasket } = this.getArticleInfo(article.id!);
    if (index >= 0) {
      shoppingBasket.basketItems?.splice(index, 1);
    } else {
      return false;
    }
    this.setLocalBasket(shoppingBasket);
    return true;
  }

  setLocalBasket(basket: BasketModel) {
    Vue.$eventbus.emit('UPDATE/SWISSMECHANIC.ONLINESHOP.SHOPPINGBASKET', basket);
    this.setStorage(this.basketStorageKey, basket);
  }

  getLocalBasket() {
    const shoppingBasketDTO = Vue.$sessionStorage.get(this.basketStorageKey) as any;
    const shoppingBasket = new BasketModel(shoppingBasketDTO ?? {} as any);
    if (!Array.isArray(shoppingBasket.basketItems)) {
      shoppingBasket.basketItems = [];
    }
    return shoppingBasket;
  }

  getPreviewArticleImageUrl(article: ArticleModel) {
    if (_.isNil(article) || _.isEmpty(article.imageDetailId)) {
      return undefined;
    }

    let url = Vue.$fetch.defaults.baseUrl;
    url += `Articles/${article.id}/PreviewImage`;
    return url;
  }

  getDetailArticleImageUrl(article: ArticleModel) {
    if (_.isNil(article) || _.isEmpty(article.imageDetailId)) {
      return undefined;
    }

    let url = Vue.$fetch.defaults.baseUrl;
    url += `Articles/${article.id}/DetailImage`;
    return url;
  }

  async updateBasketFromServer(setLocalStorage: boolean = true) {
    const basket = this.getLocalBasket();
    const lightItems = basket.basketItems?.map((item) => (new BasketItemModel({
      article: {
        abbreviation: item.article!.abbreviation,
        id: item.article!.id,
        articleNumber: Number.parseFloat(item.article!.abbreviation!),
        weight: undefined,
      },
      count: item.count,
    }))) ?? [];
    const lightBasket = new UpdateBasketRequestModel();
    lightBasket.basketItems = lightItems;
    const response = await Vue.$service.v2.api.shop.updateBasket(lightBasket);
    if (setLocalStorage) {
      this.setLocalBasket(response.data);
    }
    return response;
  }

  setStorage(key: string, value: any) {
    let dto = value;
    if (dto && typeof (dto as BaseModel<any>).getDTO === 'function') {
      dto = (dto as BaseModel<any>).getDTO();
    }
    Vue.$sessionStorage.set(key, dto);
  }

  getStorage<T>(key: string, modelType?: new(dto: any) => T): T | null {
    const dto = Vue.$sessionStorage.get(key) as any;
    if (dto === null || dto === undefined) {
      return dto;
    }
    if (modelType) {
      // eslint-disable-next-line new-cap
      return new modelType(dto || {} as any);
    }
    return dto;
  }

  setAccount(account: UserAccountModel | UserAccountDTO) {
    this.setStorage('account', account);
  }

  getAccount(): UserAccountModel | null {
    return this.getStorage('account', UserAccountModel);
  }

  setIsMember(isMember: boolean) {
    this.setStorage('isMember', isMember);
  }

  getIsMember(): boolean {
    return this.getStorage<boolean>('isMember') ?? false;
  }

  setWantsToBecomeMember(wantsToBecomeAMember: boolean) {
    this.setStorage('wantsToBecomeAMember', wantsToBecomeAMember);
    this.setIsMember(wantsToBecomeAMember);
  }

  getWantsToBecomeMember(): boolean {
    return this.getStorage<boolean>('wantsToBecomeAMember') ?? false;
  }

  setShippingAddress(value: AddressModel | AddressDTO) {
    this.setStorage('addressShipping', value);
  }

  getShippingAddress(): AddressModel | null {
    return this.getStorage('addressShipping', AddressModel);
  }

  setInvoiceAddress(value: AddressModel | AddressDTO) {
    this.setStorage('addressInvoice', value);
  }

  getInvoiceAddress(): AddressModel | null {
    return this.getStorage('addressInvoice', AddressModel);
  }

  setIsShippingSameAsInvoice(value: boolean) {
    this.setStorage('isShippingSameAsInvoice', value);
  }

  getIsShippingSameAsInvoice(): boolean {
    return this.getStorage<boolean>('isShippingSameAsInvoice') ?? false;
  }

  setClientRemark(value: string) {
    this.setStorage('clientRemark', value);
  }

  getClientRemark(): string | undefined {
    return this.getStorage<string | undefined>('clientRemark') ?? undefined;
  }

  async sendOrder() {
    await this.updateBasketFromServer();
    const { id } = this.getAccount()!;
    const { basketItems } = this.getLocalBasket();
    const isMember: boolean = this.getIsMember() ?? false;
    const wantsToBecomeAMember: boolean = this.getWantsToBecomeMember() ?? false;
    const addressInvoice = this.getInvoiceAddress();
    let addressShipping = this.getShippingAddress();
    const isShippingSameAsInvoice = this.getIsShippingSameAsInvoice();
    if (isShippingSameAsInvoice) {
      addressShipping = addressInvoice;
    }
    const addresses = [{ ...addressInvoice, addressType: 0 }, { ...addressShipping, addressType: 1 }];
    const clientRemark = this.getClientRemark();
    const order = {
      userId: id?.toString(),
      wantsToBecomeAMember,
      priceCategory: isMember ? 'member' : 'standard',
      clientRemark,
      basket: {
        isShippingSameAsInvoice,
        addresses,
        basketItems,
      },
    };
    const responsePostOrder = await Vue.$service.v2.api.shop.createOrder(new CreateOrderRequestModel(order as any));
    this.setLastOrderNumber(responsePostOrder.data);
  }
}

const shoppingBasket = new ShoppingBasketUtils();
export default shoppingBasket;
