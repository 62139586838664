// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BasketSchema from './basket-schema';

const schema = {
  $id: '/create-order-request-schema',
  type: 'object',
  required: [],
  properties: {
    userId: {
      type: 'string',
      nullable: true,
    },
    orderNumber: {
      type: 'string',
      nullable: true,
    },
    wantsToBecomeAMember: {
      type: 'boolean',
    },
    priceCategory: {
      type: 'string',
      nullable: true,
    },
    clientRemark: {
      type: 'string',
      nullable: true,
    },
    basket: {
      ...BasketSchema,
    },
  },
} as IJSONSchema;

export default schema;
