// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';
import EventOccurenceSchema from './event-occurence-schema';

const schema = {
  $id: '/widget-event-schema',
  type: 'object',
  required: ['id', 'durationDays', 'durationHourTarget', 'eventNr', 'costsPerParticipant', 'participationCosts'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    certificate: {
      type: 'string',
      nullable: true,
    },
    place: {
      type: 'string',
      nullable: true,
    },
    comment: {
      type: 'string',
      nullable: true,
    },
    contactEmail: {
      type: 'string',
      nullable: true,
    },
    contactPerson: {
      type: 'string',
      nullable: true,
    },
    subtitle: {
      type: 'string',
      nullable: true,
    },
    costs: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    documentation: {
      type: 'string',
      nullable: true,
    },
    preparation: {
      type: 'string',
      nullable: true,
    },
    registrationDeadline: {
      type: 'string',
      nullable: true,
    },
    cancellation: {
      type: 'string',
      nullable: true,
    },
    workingHours: {
      type: 'string',
      nullable: true,
    },
    catering: {
      type: 'string',
      nullable: true,
    },
    travelExpenses: {
      type: 'string',
      nullable: true,
    },
    duration: {
      type: 'string',
      nullable: true,
    },
    dates: {
      type: 'string',
      nullable: true,
    },
    durationDays: {
      type: 'integer',
    },
    durationHourTarget: {
      type: 'integer',
    },
    eventNr: {
      type: 'integer',
    },
    intendedDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    openDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    leader: {
      type: 'string',
      nullable: true,
    },
    meetingPoint: {
      type: 'string',
      nullable: true,
    },
    notes: {
      type: 'string',
      nullable: true,
    },
    administration: {
      type: 'string',
      nullable: true,
    },
    organisation: {
      type: 'string',
      nullable: true,
    },
    remarks: {
      type: 'string',
      nullable: true,
    },
    costsPerParticipant: {
      type: 'number',
    },
    participationCosts: {
      type: 'number',
    },
    eventManager: {
      type: 'string',
      nullable: true,
    },
    previousKnowledge: {
      type: 'string',
      nullable: true,
    },
    requirements: {
      type: 'string',
      nullable: true,
    },
    schedule: {
      type: 'string',
      nullable: true,
    },
    targetAudience: {
      type: 'string',
      nullable: true,
    },
    eventGoals: {
      type: 'string',
      nullable: true,
    },
    teachingAid: {
      type: 'string',
      nullable: true,
    },
    teachingMethod: {
      type: 'string',
      nullable: true,
    },
    eventTeachingMethod: {
      type: 'string',
      nullable: true,
    },
    times: {
      type: 'string',
      nullable: true,
    },
    name: {
      type: 'string',
      nullable: true,
    },
    abbreviation: {
      type: 'string',
      nullable: true,
    },
    niveaus: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    categories: {
      type: 'array',
      nullable: true,
      items: {
        type: 'string',
      },
    },
    startDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    endDate: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    imageUrl: {
      type: 'string',
      nullable: true,
    },
    imageThumbUrl: {
      type: 'string',
      nullable: true,
    },
    price: {
      type: 'number',
      nullable: true,
    },
    registrationStatus: {
      type: 'string',
      nullable: true,
    },
    registrationIndicator: {
      type: 'string',
      nullable: true,
    },
    isOnlineRegistrationActive: {
      type: 'boolean',
    },
    detailUrl: {
      type: 'string',
      nullable: true,
    },
    eventType: {
      type: 'integer',
      nullable: true,
    },
    registrationFormUrl: {
      type: 'string',
      nullable: true,
    },
    eventInformationDocumentUrl: {
      type: 'string',
      nullable: true,
    },
    eventDocumentsUrl: {
      type: 'string',
      nullable: true,
    },
    locationMapUrl: {
      type: 'string',
      nullable: true,
    },
    occurences: {
      type: 'array',
      nullable: true,
      items: { ...EventOccurenceSchema },
    },
  },
} as IJSONSchema;

export default schema;
